
import { Component, Vue } from 'vue-property-decorator';
import { withdrawUser } from '@/api/user';
import { UserModule } from '@/store/user';
import MypageProfile from './components/profile.vue';

@Component({
  components: {
    MypageProfile,
  },
})

export default class extends Vue {
  // mounted() { }
  private pointInfo(pointInfo : any) {
    console.log(pointInfo);
  }

  private handleWithdraw() {
    this.$prompt('정말 회원을 탈퇴하시겠습니까? \n 회원탈퇴를 원하시면 \'남해군 원스톱 관광플랫폼 회원탈퇴\'를 입력하세요', '회원탈퇴', {
      confirmButtonText: '회원탈퇴',
      cancelButtonText: '취소',
    }).then((value: any) => {
      if (value.value === '남해군 원스톱 관광플랫폼 회원탈퇴') {
        this.$confirm('정말 회원을 탈퇴하시겠습니까?', '회원탈퇴', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'warning',
        }).then(() => {
          withdrawUser().then(() => {
            this.$message({
              type: 'info',
              message: '성공적으로 회원탈퇴 되셨습니다.',
            });
            UserModule.LogOut();
            this.$router.push({ name: 'Login' });
          }).catch(() => {
            this.$message.error('회원탈퇴에 실패하였습니다.');
          });
        });
      } else {
        this.$message.info('문구가 올바르지 않습니다.');
      }
    });
  }

  private handleLogout() {
    UserModule.LogOut();
    this.$router.push({ name: 'Home' });
  }
}
